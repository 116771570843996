.components {
  &__footer {
    height: var(--default-layout-footer-height);
    padding: 0 15px;
    position: fixed;
    bottom: 0;
    background: #fff;
    z-index: 1000;
    width: 100%;
    border: 1px solid #e8e8e8;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-img-logo {
      height: 80%;
    }

    &-brand {
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      color: #414141;
    }
    &-copyright {
      padding-right: 20px;
    }
    &-info {
      span {
        padding: 0px 6px;
        color: #414141;
        font-size: 14px;

        &:first-child {
          border-right: 1px solid #414141;
        }
        a {
          font-family: 'Arial';
          font-style: normal;
          font-size: 14px;
          line-height: 19px;
          color: rgb(255, 0, 38);
          text-decoration: underline rgb(255, 0, 38) solid !important;
        }
      }
    }
  }
}

.components__footer-opacity {
  height: 3.578%;
  background: #fff;
  z-index: 1;
  width: 100%;
}
